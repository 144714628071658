import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import Visability from '../components/Visability';
import LockChip from '../components/LockChip';
import clx from 'classnames';
import styles from './Input.module.css';
import { LabelSlot } from '../components/LabelSlot';
import { useTranslateHook } from 'common/hooks/useTranslate';
import { IoIosWarning } from 'react-icons/io';

interface MInputProps {
  autoFocus?: boolean;
  className?: string;
  dataTestId?: string;
  /** @deprecated defaultValue */
  defaultValue?: string;
  /** @deprecated inputRef */
  inputRef?: React.Ref<HTMLInputElement>;
  inputWrapperClassName?: string;
  isLocked?: boolean;
  label?: string | null | false | ReactNode;
  labelRight?: string | null | false | ReactNode;
  LeadingIcon?: FC<{ className?: string }>;
  name?: string;
  /** @deprecated open */
  open?: boolean;
  placeHolder?: string;
  /** @deprecated required */
  required?: boolean;
  step?: string | number;
  type?: 'text' | 'password' | 'email' | 'number' | 'time' | 'date';
  value?: string | number;
  warning?: boolean;
  warningMessage?: string;
  warningMessageIcon?: boolean;
  withMargin?: boolean;
  readOnly?: boolean;
  variant?: 'base' | 'reverse';
  /** @deprecated onClick */
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleUnLockField?: (name: string) => void;
  removeHandler?: any;
  removeLabel?: string;
  hideRemove?: boolean;
  lockedMessage?: string;
  disabledRemoveHandler?: boolean;
  disabled?: boolean;
  readOnlyWithAction?: boolean;
  handleKeyDown?: any;
  handlePaste?: any;
}

export const MInput = ({
  autoFocus,
  className,
  inputWrapperClassName,
  dataTestId = 'mui-input',
  isLocked,
  label,
  labelRight,
  LeadingIcon,
  name,
  placeHolder,
  step,
  type = 'text',
  value,
  warning,
  withMargin = true,
  variant,
  readOnly = false,
  handleBlur,
  handleChange,
  handleFocus,
  handleUnLockField,
  handleClick,
  removeHandler,
  removeLabel,
  hideRemove,
  lockedMessage,
  warningMessage,
  warningMessageIcon,
  disabledRemoveHandler,
  disabled,
  readOnlyWithAction,
  handleKeyDown,
  handlePaste,
}: MInputProps) => {
  const t = useTranslateHook();
  const [inputType, setInputType] = useState(type);
  const inputRef = useRef<any>();

  const onFocus = (e: any) => {
    handleFocus && handleFocus(e);
  };
  const onBlur = (e: any) => {
    handleBlur && handleBlur(e);
  };

  useEffect(() => {
    autoFocus && inputRef.current && inputRef.current.focus();
  }, []);

  const ToggleVisability = () => {
    const inputValue = inputType === 'password' ? 'text' : 'password';
    setInputType(inputValue);
  };

  return (
    <div
      className={clx(styles.FieldWrapper, className, {
        [styles.Locked]: isLocked,
        [styles.Password]: type === 'password',
        [styles.Error]: warning,
        [styles.Margin]: withMargin,
        [styles.WithLeadingIcon]: LeadingIcon,
        [styles.Reverse]: variant === 'reverse',
      })}
      data-testid={dataTestId}
    >
      {label && <LabelSlot label={label} right={labelRight} />}
      {removeHandler && !hideRemove && (
        <LabelSlot
          label={`${removeLabel}`}
          right={
            <button
              className={styles.RemoveBtn}
              onClick={removeHandler}
              disabled={disabledRemoveHandler}
            >
              {t('Remove')}
            </button>
          }
        />
      )}
      <div
        className={clx(styles.InputWrapper, inputWrapperClassName)}
        onClick={handleClick}
      >
        {LeadingIcon && <LeadingIcon className={styles.LeadingIcon} />}
        <input
          autoComplete="off"
          autoFocus={autoFocus}
          className={clx(styles.Input, {
            [styles.Disabled]: disabled,
            [styles.InputLocked]: isLocked,
            [styles.ReadOnly]: readOnlyWithAction,
            className,
          })}
          data-testid="mui-input-input"
          disabled={disabled || isLocked}
          name={name}
          onBlur={onBlur}
          onChange={handleChange}
          onFocus={onFocus}
          placeholder={placeHolder}
          ref={inputRef}
          step={step}
          type={inputType}
          value={value}
          readOnly={readOnly}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
        />
        <div className={styles.Overlay}>
          <Visability
            inputType={inputType}
            ToggleVisability={ToggleVisability}
            type={type}
          />
          {isLocked && handleUnLockField && (
            <LockChip
              name={name}
              handleUnLockField={handleUnLockField}
              lockedMessage={lockedMessage}
            />
          )}
        </div>
      </div>
      {warningMessage && (
        <div className={styles.WarningMessage}>
          {warningMessageIcon && <IoIosWarning />}
          <span>{warningMessage}</span>
        </div>
      )}
    </div>
  );
};

export default MInput;
