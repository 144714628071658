import { useState } from 'react';
import styles from './TimePicker.module.css';
import { ArrowDown, FieldInput, FieldWrapper, LabelSlot } from '../components';
import { formatTime, displayTime } from './utils';
import { NumberSelector } from './components';

interface TimePickerProps {
  hours?: number;
  label?: string;
  maxHours?: number;
  maxMinutes?: number;
  minHours?: number;
  minMinutes?: number;
  minutes?: number;
  name?: string;
  placeholder?: string;
  onSelectHours?: (newValue: number) => void;
  onSelectMinutes?: (newValue: number) => void;
}

export const TimePicker = ({
  hours = 0,
  label,
  maxHours = 23,
  maxMinutes = 59,
  minHours = 0,
  minMinutes = 0,
  minutes = 0,
  name,
  placeholder,
  onSelectHours,
  onSelectMinutes,
}: TimePickerProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const toggle = () => setOpen((v) => !v);

  return (
    <div className={styles.Wrapper}>
      <FieldWrapper withMargin={false}>
        {label && <LabelSlot label={label} />}
        <div
          style={{
            cursor: 'pointer',
          }}
        >
          <FieldInput
            className={styles.Input}
            onClick={toggle}
            value={displayTime(hours, minutes)}
            right={<ArrowDown open={open} />}
            isActive={open}
          />

          {open && onSelectHours && onSelectMinutes && (
            <div className={styles.Selectors}>
              <NumberSelector
                value={hours}
                min={0}
                max={23}
                handleSelect={onSelectHours}
              />
              <NumberSelector
                value={minutes}
                min={0}
                max={59}
                handleSelect={onSelectMinutes}
              />
            </div>
          )}
        </div>
      </FieldWrapper>
    </div>
  );
};
