import { useClickOutside } from 'common/hooks/useClickOutside';
import { useRef, useState } from 'react';
import Chip from '../components/Chip';
import FieldSet from '../components/FieldSet';
import Input from '../components/Input';
import Label from '../components/Label/Label';
import LoadingSpinner from '../components/LoadingSpinner';
import Options from '../components/Options';
import SearchIcon from '../components/SearchIcon';

const AutoSelect = ({
  label,
  options,
  value,
  handleSelect,
  handleChange,
  loading,
  selected,
  remove,
  resetOptions,
  name,
  multiple,
  focused,
  placeHolder
}: any) => {
  const inputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const clickOutside = () => {
    setOpen(false);
  };
  useClickOutside(inputRef, () => clickOutside());
  return (
    <>
      <style jsx>{`
        .select_outer_wrapper: {
          min-width: 120px;
          min-height: 100px;
          position: relative;
          z-index: 5;
          min-width: 250px;
          margin: 8px 0;
        }

        .input {
          bottom: 0;
          left: 0;
          position: absolute;
          opacity: 0;
          pointer-events: none;
          width: 100%;
          box-sizing: border-box;
        }
        .select_inner_wrapper {
          display: -webkit-inline-box;
          display: -webkit-inline-flex;
          display: -ms-inline-flexbox;
          display: inline-flex;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
          position: relative;
          min-width: 0;
          padding: 0;
          margin: 0;
          border: 0;
          vertical-align: top;
          width: 100%;
          z-index: 5;
          min-width: 250px;
        }
        .tab_wrapper {
          padding-right: 32px;
          min-height: 45px;
        }
        .list_item:hover {
          background-color: #f3f3f3;
        }
        .select_inner_wrapper:focus {
          outline: none;
        }
        .select_outer_wrapper:focus {
          outline: none;
        }
        .auto_input {
          z-index: 6000;
          padding: 3px 0;
          margin: 2px 0;
          border: 0;
          background: transparent;
        }
        .auto_input:focus {
          outline: 0;
        }
        .tab_wrapper {
          min-height: 45px;
        }
        .tab_inner_wrapper {
          display: flex;
          padding: 6px 14px;
          font-size: 14px;
          font-family: Nunito;
          color: rgba(0, 0, 0, 0.87);
          gap: 8px;
          flex-wrap: wrap;
          align-items: center;
        }
        @media (max-width: 980px) {
          .tab_inner_wrapper {
            display: grid;
            grid-template-columns: 1fr;
          }
          .tab_wrapper {
            min-height: 100%;
            height: 100%;
          }
          .label_wrapper {
            transform: translate(0px, -2px);
          }
        }
      `}</style>

      <div
        className="select_outer_wrapper"
        //  style={{ margin: "8px 0" }}
        contentEditable={false}
      >
        <div style={{ position: 'relative' }}>
          <div className="label_wrapper">
            <Label
              open={open}
              value={value}
              selected={focused}
              label={label}
              alignRight={true}
            />
          </div>
          <div className="select_inner_wrapper">
            <div className="tab_wrapper">
              <div className="tab_inner_wrapper">
                {loading && <LoadingSpinner />}
                {!loading && <SearchIcon />}
                {!multiple && selected && (
                  <Chip remove={remove} selected={selected} />
                )}
                {multiple &&
                  selected &&
                  selected.map((item: any, i: number) => {
                    return <Chip key={i} remove={remove} selected={item} />;
                  })}
                <Input
                  onFocus={() => {
                    setOpen(true);
                  }}
                  onBlur={() => {
                    setOpen(false);
                  }}
                  name={name}
                  autocomplete="off"
                  value={value}
                  handleChange={handleChange}
                />
              </div>
            </div>
            <FieldSet
              open={open}
              value={value}
              selected={focused}
              label={label}
            />
          </div>
        </div>
        <Options
          open={open}
          options={options}
          resetOptions={resetOptions}
          handleSelect={handleSelect}
        />
      </div>
    </>
  );
};

export default AutoSelect;
