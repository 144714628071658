import {
  forwardRef,
  ReactNode,
  useRef,
  useState,
  RefObject,
  MouseEvent,
  SyntheticEvent,
  FC,
} from 'react';
import styles from './FieldInput.module.css';
import clx from 'classnames';
import { Value } from '../Value';

interface FieldInputProps {
  activeClassName?: string;
  children?: ReactNode;
  className?: string;
  Icon?: FC;
  isActive?: boolean;
  left?: ReactNode;
  placeHolder?: string;
  right?: ReactNode;
  shouldLock?: boolean;
  value?: any;
  disabled?: boolean,
  noCursor?: boolean;
  onClick?: (
    event: MouseEvent<HTMLDivElement> | SyntheticEvent<HTMLDivElement>,
  ) => void;
}

export const FieldInput = forwardRef<HTMLDivElement, FieldInputProps>(
  (
    {
      activeClassName,
      placeHolder,
      Icon,
      left,
      isActive,
      shouldLock,
      onClick,
      children,
      value,
      right,
      className,
      disabled,
      noCursor
    }: FieldInputProps,
    ref,
  ) => {
    return (
      <div
        className={clx(
          styles.FieldInput,
          !!activeClassName && isActive ? activeClassName : '',
          {
            [styles.Active]: isActive,
            [styles.Locked]: shouldLock,
            [styles.Disabled]: disabled,
            [styles.noCursor]: noCursor
          },
          className,
        )}
        onClick={onClick}
        ref={ref}
      >
        <div className={styles.FieldInputLeft}>
          {Icon && <Icon />}
          {left && left}
          {!children && !value && placeHolder && (
            <span className={styles.PlaceHolder}>{placeHolder}</span>
          )}
          {children && children}
          {value && <Value value={value} className={clx({
            [styles.ValueDisabled]: disabled,
          })} />}
        </div>
        {right && right}
      </div>
    );
  },
);

FieldInput.displayName = 'FieldInput';
