import { useContext, useState } from 'react';
import FieldSet from '../components/FieldSet';
import Input from '../components/Input';
import InputWrapper from '../components/InputWrapper';
import Label from '../components/Label';
import { AiOutlineSearch } from 'react-icons/ai';
import { ThemeContext } from 'common/context/Theme';
import { themeTypes } from 'common/context/Theme/ThemeContext';

const SearchInput = ({
  value,
  warning,
  label,
  handleChange,
  isLocked,
  open,
  name,
  type,
  autoFocus,
}: any) => {
  const { secondaryColor, theme, primaryColor, themeBackground } =
    useContext<themeTypes>(ThemeContext);
  const [inputType, setInputType] = useState(type);
  const [focused, setFoccused] = useState(false);
  const onFoccus = () => {
    setFoccused(!focused);
  };
  return (
    <>
      <style jsx>{`
        .wrapper {
          display: inline-flex;
          flex-direction: column;
          position: relative;
          min-width: 0px;
          padding: 0px;
          margin: 0px;
          border: 0px;
          vertical-align: top;
          width: 100%;
          margin: 8px 0;
        }
        .select_inner_wrapper {
          display: -webkit-inline-box;
          display: -webkit-inline-flex;
          display: -ms-inline-flexbox;
          display: inline-flex;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
          position: relative;
          min-width: 0;
          padding: 0;
          margin: 0;
          border: 0;
          vertical-align: top;
          width: 100%;
          z-index: 5;
          min-width: 250px;
        }
        .icon {
          color: ${primaryColor};
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 22px;
        }
      `}</style>
      <div className="wrapper">
        <div style={{ width: 50 }} />
        <Label
          focused={focused}
          open={open}
          value={value}
          warning={warning}
          label={label}
          alignRight={true}
        />
        <div className="select_inner_wrapper">
          <InputWrapper>
            <div className="icon">
              <AiOutlineSearch />
            </div>
            <Input
              inputType={inputType}
              name={name}
              value={value}
              handleChange={handleChange}
              onFoccus={onFoccus}
              autoFocus={autoFocus}
            />
          </InputWrapper>
          <FieldSet focused={focused} open={open} value={value} label={label} />
        </div>
      </div>
    </>
  );
};

export default SearchInput;
