import React from 'react';
import styles from '../TimePicker.module.css';
import clx from 'classnames';
import { formatTime, displayTime } from '../utils';

interface NumberBlockProps {
  value: number;
  onClick: (newValue: number) => void;
  selected: boolean;
}

export const NumberBlock: React.FC<NumberBlockProps> = ({
  value,
  onClick,
  selected,
}) => {
  const handleClick = () => {
    onClick(value);
  };

  return (
    <div
      className={clx(styles.Number, selected && styles.SelectedNumber)}
      onClick={handleClick}
    >
      {formatTime(value, 59)}
    </div>
  );
};
