import MoonLoader from 'react-spinners/MoonLoader';

interface LoadingSpinnerProps {
  color?: string;
  className?: string;
  size?: number;
}

const LoadingSpinner = ({
  size = 16,
  color = '#2196f3',
}: LoadingSpinnerProps) => {
  return <MoonLoader color={color} size={size} cssOverride={{ opacity: 1 }} />;
};

export default LoadingSpinner;
