import { useClickOutside } from 'common/hooks/useClickOutside';
import { FC, useRef, useState } from 'react';
import { BsCalendar3 } from 'react-icons/bs';
import FieldSet from '../components/FieldSet';
import InputWrapper from '../components/InputWrapper';
import Calendar from 'react-calendar';
import { renderShortDate } from 'common/utils/date';
import { formatDate } from 'common/utils/date';
import { ThemeContext } from 'common/context/Theme';
import { themeTypes } from 'common/context/Theme/ThemeContext';
import { useContext } from 'react';
import { FieldWrapper, LabelSlot } from '../components';
import { AiOutlineClockCircle } from 'react-icons/ai';

type Props = {
  warning?: boolean;
  label: string;
  title?: string;
  date: Date;
  defaultValue?: string;
  handleChangeDate?: (date: any) => void;
  type?: string;
  pickerType?: string;
  margin?: boolean;
};

const DatePicker: FC<Props> = ({
  warning,
  label,
  title,
  date,
  defaultValue,
  handleChangeDate,
  type = 'short',
  pickerType = 'time',
  margin = false,
}: any) => {
  const { primaryColor, secondaryColor } = useContext<themeTypes>(ThemeContext);
  const [open, setOpen] = useState(false);
  const mainEl = useRef(null);

  useClickOutside(mainEl, () => setOpen(false));
  const handleChangeTime = (e: any) => {
    const { value } = e.target;
    let ms =
      Number(value.split(':')[0]) * 60 * 60 * 1000 +
      Number(value.split(':')[1]) * 60 * 1000;
  };

  const onSelect = (date: Date) => {
    handleChangeDate(date);
    setOpen(false);
  };

  return (
    <FieldWrapper withMargin={margin}>
      {label && <LabelSlot label={label} />}

      <style jsx>{`
        .select_inner_wrapper {
          display: -webkit-inline-box;
          display: -webkit-inline-flex;
          display: -ms-inline-flexbox;
          display: inline-flex;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
          position: relative;
          min-width: 0;
          padding: 0;
          margin: 0;
          border: 0;
          vertical-align: top;
          width: 100%;
          z-index: 5;
          min-width: 100%;
        }
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          cursor: pointer;
          z-index: 100000000;
        }
        .date_popup_wrapper {
          background: #ffffff;
          box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          position: absolute;
          padding: 10px;
          top: 10px;
          right: 0;
          z-index: 5000000000;
          display: grid;
          grid-template-columns: 1fr;
          row-gap: 15px;
        }
        .time_wrapper {
          background: #ffffff;
          box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
          border-radius: 8px;
          padding: 8px;
          width: 100%;
        }
        .title {
          border: 0;
          color: ${secondaryColor};
          align-self: center;
          width: 100%;
          font-family: Nunito;
          line-height: 1.4375em;
          letter-spacing: 0.00938em;
          font-weight: 400;
        }
        .wrapper {
          position: relative;
          margin: ${margin ? 8 : 0}px 0;
        }
        @media (max-width: 620px) {
          .title {
            font-size: 18px;
            // transform: translate(14px, 28px) scale(1);
          }
          .date_popup_wrapper {
            right: -15px;
          }
        }
      `}</style>
      <div>
        <div className="wrapper">
          <div className="select_inner_wrapper">
            <InputWrapper>
              <div className="title">
                {' '}
                {date
                  ? type === 'short'
                    ? renderShortDate(date)
                    : formatDate(date)
                  : title}
              </div>
              {handleChangeDate && (
                <div onClick={() => setOpen(!open)} className="icon">
                  <AiOutlineClockCircle />
                </div>
              )}
            </InputWrapper>
            <FieldSet open={open} value={date} label={''} />
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          {open && (
            <div ref={mainEl} className="date_popup_wrapper">
              <Calendar
                defaultValue={defaultValue}
                onChange={onSelect}
                value={date}
              />
              {type === 'long' && (
                <div className="time_wrapper">
                  <div className="time">
                    <input onChange={handleChangeTime} type={pickerType} />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </FieldWrapper>
  );
};

export default DatePicker;
