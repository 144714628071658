import { ReactNode } from 'react';
import styles from './LabelSlot.module.css';
import clx from 'classnames';

interface LabelSlotProps {
  label?: string | ReactNode;
  right?: string | ReactNode;
  className?: string;
  disabled?: boolean;
}

export const LabelSlot = ({ right, label, className, disabled }: LabelSlotProps) => {
  return (
    <div className={clx(styles.LabelSlot, {
      [styles.disabled]: disabled,
    }, className)}>
      {label && <div>{label}</div>}
      {right && <div className={styles.Right}>{right}</div>}
    </div>
  );
};
