import styles from './SelectPopup.module.css';
import clx from 'classnames';
import { ReactNode, forwardRef } from 'react';

export interface SelectPopupProps {
  children: ReactNode;
  open: boolean;
  className?: string;
}

export const SelectPopup = forwardRef<HTMLDivElement, SelectPopupProps>(
  ({ className, open, children }: SelectPopupProps, ref) => {
    return open ? (
      <div className={clx(styles.Popup, className)} ref={ref}>
        <div className={styles.Wrapper}>{children}</div>
      </div>
    ) : null;
  },
);

SelectPopup.displayName = 'SelectPopup';
