import { ThemeContext } from 'common/context/Theme';
import { themeTypes } from 'common/context/Theme/ThemeContext';
import { useContext, useEffect, useRef } from 'react';
import clx from 'classnames';

const Input = ({
  value,
  handleChange,
  name,
  inputType = 'text',
  autoFocus,
  autocomplete = 'on',
  onFocus,
  onBlur,
  placeHolder,
  className,
}: any) => {
  const inputRef = useRef<any>();
  const { secondaryColor } = useContext<themeTypes>(ThemeContext);

  useEffect(() => {
    autoFocus && inputRef.current && inputRef.current.focus();
  }, []);
  return (
    <>
      <style jsx>{`
        .input {
          z-index: 6000;
          padding: 3px 0;
          margin: 2px 0;
          border: 0;
          flex-grow: 1;
          background: transparent;
          font-family: 'Figtree';
          font-style: normal;
          font-weight: 400;
          font-size: var(--mui-input-font-size);
          line-height: 20px;
          color: ${secondaryColor};
        }
        .input:focus {
          outline: 0;
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:focus {
          -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
        }
        @media (max-width: 620px) {
          .input {
            font-size: 18px;
          }
        }
      `}</style>
      <input
        autoFocus={autoFocus}
        onFocus={onFocus}
        onBlur={onBlur}
        type={inputType}
        className={clx('input', className)}
        name={name}
        value={value}
        onChange={handleChange}
        ref={inputRef}
        autoComplete={autocomplete}
        placeholder={placeHolder}
      />
    </>
  );
};

export default Input;
