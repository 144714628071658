import { useState, useEffect, useCallback } from 'react';
import { NormalizedOption, OptionItem } from './types';

interface useKeyboardSelectV2Input {
  normalizedOptions: NormalizedOption[];
  open: boolean;
  select: (item: OptionItem) => void;
}

interface useKeyboardSelectV2Output {
  activeIndex: number | null;
  handleOptionHover: (i: number | null) => void;
}

export const useKeyboardSelectV2 = ({
  select,
  open,
  normalizedOptions,
}: useKeyboardSelectV2Input): useKeyboardSelectV2Output => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  useEffect(() => {
    if (open) {
      setActiveIndex(normalizedOptions.length ? 0 : null);
    } else {
      setActiveIndex(null);
    }
  }, [normalizedOptions, open]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      try {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'Enter') {
          e.stopPropagation();
          e.preventDefault();
        }
        const optionsCount = normalizedOptions.length;
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
          setActiveIndex((a) => {
            if (e.key === 'ArrowUp') {
              return Math.max(0, (a || 0) - 1);
            }
            if (e.key === 'ArrowDown') {
              return Math.min(optionsCount - 1, (a || 0) + 1);
            }
            return null;
          });
        }
        if (e.key === 'Enter') {
          const selectedOption =
            normalizedOptions && activeIndex
              ? normalizedOptions[activeIndex]
              : null;
          if (selectedOption) {
            select(selectedOption.item);
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    [normalizedOptions, select, activeIndex],
  );

  useEffect(() => {
    if (open) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [open, handleKeyDown]);

  const handleOptionHover = useCallback((i: number | null) => {
    setActiveIndex(i);
  }, []);

  return {
    activeIndex,
    handleOptionHover,
  };
};
