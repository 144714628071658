import LockIcon from 'common/Icons/Lock';
import styles from './lockedChip.module.css';

import { Tooltip } from 'react-tooltip';

const LockChip = ({ handleUnLockField, name, lockedMessage = '' }: any) => {
  return (
    <>
      <a data-tooltip-id={`my-tooltip-${name}`}>
        <div className={styles.chip} onClick={() => handleUnLockField(name)}>
          <div className={styles.chip_name}>
            {' '}
            <LockIcon />
          </div>
        </div>
      </a>
      <Tooltip
        id={`my-tooltip-${name}`}
        content={lockedMessage}
        place={'bottom-end'}
        style={{ backgroundColor: '#4C4C4D', borderRadius: '4px' }}
      />
    </>
  );
};

export default LockChip;
