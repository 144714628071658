import styles from './SelectOptions.module.css';
import clx from 'classnames';
import { capitalizeFirstLetter } from 'common/utils/case';
import { displayValueV2, extractOptionLeadingIcon, normalizeOptionsV2 } from './utils';
import { NormalizedOption, Options, OptionItem } from './types';
import { useMemo } from 'react';
import { useKeyboardSelectV2 } from './useKeyboardSelectV2'; 
 
export interface SelectV2OptionsProps {
  activeIndex?: number | null;
  options: Options | NormalizedOption[];
  selected: OptionItem | OptionItem[];
  capitalize?: boolean;
  displayEmpty?: boolean;
  emptyLabel?: string;
  emptyValue?: OptionItem;
  highlightPhrase?: string;
  isVisible?: boolean;
  onSelect: (option: OptionItem) => void;
  handleOptionHover?: (i: number | null) => void;
}

export const SelectV2Options = ({
  options,
  capitalize = false,
  isVisible = false,
  displayEmpty,
  emptyLabel,
  emptyValue,
  highlightPhrase,
  onSelect,
}: SelectV2OptionsProps) => {
  const normalizedOptions = useMemo(() => normalizeOptionsV2(options), [options]);

  const { activeIndex, handleOptionHover } = useKeyboardSelectV2({
    select: onSelect,
    open: isVisible || false,
    normalizedOptions,
  });

  console.log("normalizedOptions", {options, normalizedOptions})

  return (
    <div className={styles.Popup}>
      <div className={styles.Wrapper}>
        <ul className={styles.List}>
          {displayEmpty && (
            <li
              onClick={() => onSelect(emptyValue || '')}
              onMouseOver={() => handleOptionHover(null)}
              className={styles.Item}
            >
              {emptyLabel}
            </li>
          )}
          {normalizedOptions.map((opt, i) => { 
            const LeadingIcon = extractOptionLeadingIcon(opt)

            return (
            <li
              key={`${opt.value}-${i}`}
              onClick={() => onSelect(opt.item)}
              className={clx(styles.Item, {
                [styles.ItemFocus]: activeIndex === i,
              })}
              onMouseOver={
                handleOptionHover ? () => handleOptionHover(i) : undefined
              }
            >
              {LeadingIcon ? (
                <LeadingIcon className={styles.OptionLeadingIcon}/>
              ) : null}
              {displayValueV2(
                capitalize
                  ? capitalizeFirstLetter(String(opt.text))
                  : String(opt.text),
                highlightPhrase,
              )}
            </li>
          )})}
        </ul>
      </div>
    </div>
  );
};
