import { ThemeContext } from 'common/context/Theme';
import { themeTypes } from 'common/context/Theme/ThemeContext';
import { useContext } from 'react';

const Warning = ({ txt }: any) => {
  const { secondaryColor } = useContext<themeTypes>(ThemeContext);

  return (
    <>
      <style jsx>{`
        .warningLabel {
          color: rgba(203, 0, 0, 0.6);
          font-family: Nunito;
          line-height: 1.4375em;
          letter-spacing: 0.00938em;
          margin: 0;
        }
      `}</style>

      <p className="warningLabel"> {txt}</p>
    </>
  );
};
export default Warning;
