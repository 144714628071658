import styles from './ArrowDown.module.css';
import clx from 'classnames';

interface ArrowDownProps {
  open?: boolean;
  className?: string;
  color?: string
}

export const ArrowDown = ({ open, className, color = '#7E7F80' }: ArrowDownProps) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        data-testid="ArrowDropDownIcon"
        className={clx(
          styles.ArrowDown,
          {
            [styles.ArrowDown_Rotate]: open,
          },
          className,
        )}
      >
        <path
          d="M11.9993 6L7.33268 10.6667L2.66602 6"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
