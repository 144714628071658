import { useClickOutside } from 'common/hooks/useClickOutside';
import { useCallback, useMemo, useRef, useState } from 'react';
import styles from './Select.module.css';
import {
  SelectPopup,
  SelectV2Options,
  FieldWrapper,
  ArrowDown,
  LabelSlot,
  FieldInput,
} from '../components';
import { OptionItem, Options } from '../components/SelectV2Options/types';
import { extractOptionLeadingIcon, normalizeOptionsV2 } from 'components/Mui/components/SelectV2Options';
import { useTranslateHook } from '@/common/hooks/useTranslate';
import clx from 'classnames'

type SelectVariant = 'primary'|'outlined'

interface MSelectV2Props {
  capitalize?: boolean;
  /** @deprecated defaultValue */
  defaultValue?: any;
  displayEmpty?: boolean;
  emptyLabel?: string;
  emptyValue?: OptionItem;
  height?: number;
  id?: string;
  label?: string;
  labelRight?: string;
  /** @deprecated margin */
  margin?: boolean;
  /** @deprecated marginTarget */
  marginTarget?: boolean;
  multiple?: boolean;
  name?: string;
  /** @deprecated outline */
  outline?: any;
  options?: Options;
  required?: boolean; // TODO: add state styles
  shouldLock?: boolean;
  /** @deprecated tipLabel */
  tipLabel?: string;
  value?: OptionItem;
  withMargin?: boolean;
  disabled?: boolean;
  placeHolder?: string;
  handleSelect?: (selected: OptionItem) => void;
  hideLabel?: boolean;
  removeHandler?: any;
  className?: string;
  variant?: SelectVariant;
}

export const MSelectV2 = ({
  label,
  labelRight,
  options,
  value,
  handleSelect,
  multiple,
  id,
  margin = false,
  marginTarget = false,
  capitalize = false,
  shouldLock = false,
  withMargin = false,
  displayEmpty = false,
  disabled = false,
  placeHolder,
  emptyLabel = '',
  emptyValue = '',
  hideLabel = false,
  removeHandler,
  className,
  variant = 'primary',
}: MSelectV2Props) => {
  const t = useTranslateHook();

  const optionsRef = useRef(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  const clickOutside = (e: MouseEvent | TouchEvent) => {
    if (e.target === inputRef.current) {
      return;
    }

    setOpen(false);
  };
  useClickOutside(optionsRef, clickOutside);

  const toggle = useCallback(() => {
    if (shouldLock) {
      return;
    }
    setOpen((open) => !open);
  }, [shouldLock]);

  const select = useCallback(
    (item: OptionItem) => {
      if (handleSelect) {
        handleSelect(item);
      }
      if (!multiple) {
        setOpen((open) => !open);
      }
    },
    [handleSelect, multiple],
  );

  const normalizedOptions = useMemo(
    () => normalizeOptionsV2(options || []),
    [options],
  );

  const LeadingIcon = useMemo(() => {
    return value ? extractOptionLeadingIcon(value) : null;
  }, [value]);

  return (
    <FieldWrapper
      className={clx(className, {
        [styles.Outlined]: variant === 'outlined',
      })}
      withMargin={withMargin || margin || marginTarget}
      id={id}
    >
      {!hideLabel && removeHandler ? (
        <LabelSlot
          disabled={disabled}
          label={`${label}`}
          right={
            <button className={styles.RemoveBtn} onClick={removeHandler}>
              {t('Remove')}
            </button>
          }
        />
      ) : label ? (
        <LabelSlot disabled={disabled} label={label} right={labelRight} />
      ) : null}
      <div
        style={{
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
      >
        <FieldInput
          ref={inputRef}
          className={styles.Input}
          onClick={toggle}
          value={value}
          Icon={LeadingIcon ? LeadingIcon : undefined}
          right={
            <ArrowDown
              color={disabled ? 'var(--mui-input-color-inactive)' : undefined}
              open={open}
            />
          }
          isActive={open}
          shouldLock={shouldLock}
          placeHolder={placeHolder}
          disabled={disabled}
        />
        <SelectPopup open={open} ref={optionsRef}>
          {options && (
            <SelectV2Options
              isVisible={open}
              onSelect={select}
              capitalize={capitalize}
              options={normalizedOptions}
              selected={[]}
              displayEmpty={displayEmpty}
              emptyValue={emptyValue}
              emptyLabel={emptyLabel}
            />
          )}
        </SelectPopup>
      </div>
    </FieldWrapper>
  );
};

export default MSelectV2;
