export const formatTime = (value: number, max: number) => {
  return Math.min(Math.max(value, 0), max).toLocaleString(undefined, {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
};

export const displayTime = (hours: number, minutes: number): string => {
  return `${formatTime(hours, 23)}:${formatTime(minutes, 59)}`;
};
