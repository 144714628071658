import clx from 'classnames';
import styles from './Value.module.css';
import { useMemo } from 'react';

interface ValueProps {
  value: any;
  capitalize?: boolean;
  className?: string;
}

export const Value = ({ className, value }: ValueProps) => {
  const displayValue = useMemo(() => {
    try {
      if (!value) return null;

      if (typeof value === 'object') {
        return Object.keys(value).length > 0
          ? Object.values(value)[0]
          : Object.values(value);
      }
      return value;
    } catch (e) {
      return '';
    }
  }, [value]);

  if (!displayValue) {
    return <></>;
  }
  return <span className={clx(styles.Value, className)}>{displayValue}</span>;
};
