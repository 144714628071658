import { ThemeContext } from 'common/context/Theme';
import { themeTypes } from 'common/context/Theme/ThemeContext';
import { useContext, useEffect, useRef, useState } from 'react';
import { useClickOutside } from 'common/hooks/useClickOutside';

const Options = ({
  className,
  options,
  handleSelect,
  resetOptions,
  showHierarchical,
  ellipsis,
}: any) => {
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const { themeBackground, secondaryColor, theme, boxShadowLight } =
    useContext<themeTypes>(ThemeContext);

  const inputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const clickOutside = () => {
    setOpen(false);
    resetOptions();
  };
  useClickOutside(inputRef, () => clickOutside());

  const select = (item: any) => {
    handleSelect(item);
    setSelectedOptions((prev: any) => {
      const newState = [...prev].filter((el: any) => el.id !== item.id);
      return newState;
    });
  };

  useEffect(() => {
    if (options && options.length > 0) {
      setOpen(true);
      setSelectedOptions(options);
    }
  }, [options]);

  return (
    <>
      <style jsx>{`
        .options_wrapper {
          position: absolute;
          width: 100%;
          z-index: 5000000;
          transform: none;
          min-width: 120px;
          top: 10px;
          box-shadow: 1px 1px 8px 2px ${boxShadowLight};
          border-radius: 4px;
          background: ${theme};
          color: ${secondaryColor};
        }
        .options_list {
          list-style: none;
          margin: 0px;
          padding: 8px 0px;
          position: relative;
          outline: 0px;
          z-index: 1000000000;
        }
        .list_item {
          -webkit-tap-highlight-color: transparent;
          background-color: transparent;
          outline: 0px;
          border: 0px;
          margin: 0px;
          border-radius: 0px;
          cursor: pointer;
          user-select: none;
          vertical-align: middle;
          appearance: none;
          color: inherit;
          font-family: Nunito;
          line-height: 1.4375em;
          letter-spacing: 0.00938em;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.5;
          letter-spacing: 0.00938em;
          display: flex;
          -webkit-box-pack: start;
          justify-content: flex-start;
          -webkit-box-align: center;
          align-items: center;
          position: relative;
          text-decoration: none;
          min-height: 48px;
          padding: 6px 16px;
          box-sizing: border-box;
          white-space: nowrap;
        }
        .list_text_elipse {
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
        }
        .list_text_elipse:hover {
          background-color: ${themeBackground};
        }
      `}</style>
      <div
        className={className}
        style={{ position: 'relative', width: '100%' }}
        contentEditable={false}
      >
        {open && selectedOptions && selectedOptions.length > 0 && (
          <div ref={inputRef} className="options_wrapper">
            <ul className="options_list">
              {selectedOptions &&
                selectedOptions.map((item: any, i: any) => {
                  return (
                    <li
                      key={i}
                      onClick={() => select(item)}
                      className="list_item"
                    >
                      <span className={ellipsis ? 'list_text_elipse' : 'text'}>
                        {' '}
                        {showHierarchical
                          ? item.hierarchicalName || item.name
                          : item.name || item.label || item.titleShort}
                      </span>
                    </li>
                  );
                })}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default Options;
