import React from 'react';
import { Tooltip } from 'react-tooltip';
import styles from './Tooltip.module.css';

interface TooltipProps {
  content: string;
  children: React.ReactNode;
}

const TooltipV2: React.FC<TooltipProps> = ({ content, children }) => {
  return (
    <>
      <a
        className={styles.Item}
        data-tooltip-id={`tool_tip_${content.toLocaleLowerCase}`}
        data-tooltip-content={content}
      >
        {children}
      </a>
      <Tooltip
        className={styles.Tooltip}
        id={`tool_tip_${content.toLocaleLowerCase}`}
      />
    </>
  );
};

export default TooltipV2;
