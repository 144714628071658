import { ReactNode, RefObject } from 'react';
import styles from './FieldWrapper.module.css';
import clx from 'classnames';

interface FieldWrapperProps {
  children?: ReactNode;
  className?: string;
  withMargin?: boolean;
  id?: string;
}

export const FieldWrapper = ({
  withMargin,
  children,
  className,
  id,
}: FieldWrapperProps) => {
  return (
    <div
      id={id}
      className={clx(
        styles.FieldWrapper,
        {
          [styles.WithMargin]: withMargin,
        },
        className,
      )}
    >
      {children}
    </div>
  );
};
