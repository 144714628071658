import { ThemeContext } from 'common/context/Theme';
import { themeTypes } from 'common/context/Theme/ThemeContext';
import { useContext } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

const Visability = ({ inputType, ToggleVisability, type }: any) => {
  const { primaryColor } = useContext<themeTypes>(ThemeContext);

  if (type !== 'password') {
    return <></>;
  }
  return (
    <>
      <style jsx>{`
        .button {
          background: 0;
          border: 0;
          z-index: 10000;
          display: flex;
          align-items: center;
          cursor: pointer;
          font-size: 20px;
          color: ${primaryColor};
        }
      `}</style>
      <div
        className="button"
        onClick={ToggleVisability}
        data-testid="mui-input-password-visibility-toggle"
      >
        {inputType === 'password' ? (
          <AiOutlineEyeInvisible />
        ) : (
          <AiOutlineEye />
        )}
      </div>
    </>
  );
};

export default Visability;
