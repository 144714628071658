import { NormalizedOption, Options, OptionItem } from './types';
import { highlightSearchPhrase } from 'common/utils/search-phrase';

export const displayValueV2 = (value: string, highlightPhrase?: string) => {
  return highlightPhrase ? (
    <span
      dangerouslySetInnerHTML={{
        __html: highlightSearchPhrase(highlightPhrase, value),
      }}
    />
  ) : (
    value
  );
};

export const extractOptionLeadingIcon = (
  opt: OptionItem | NormalizedOption,
) => {
  try {
    if (opt && typeof opt === 'object') {
      if ('LeadingIcon' in opt && opt.LeadingIcon) {
        return opt.LeadingIcon;
      }
      const item =
        'item' in opt && opt.item && typeof opt.item === 'object'
          ? opt.item
          : opt;
      return item?.data?.LeadingIcon;
    }
  } catch (e) {
    return undefined
  }
  return undefined;
};

export const normalizeOptionsV2 = (
  options: Options | NormalizedOption[],
): NormalizedOption[] => {
  if (Array.isArray(options)) {
    return options.map((opt: OptionItem | NormalizedOption) => {
      if (
        typeof opt === 'object' &&
        'value' in opt &&
        'text' in opt &&
        'item' in opt &&
        ['string', 'number'].includes(typeof opt.value) &&
        typeof opt.text === 'string'
      ) {
        return {
          value: opt.value,
          text: opt.text,
          item: opt.item,
        };
      }
      if (typeof opt === 'number' || typeof opt === 'string') {
        return {
          value: opt,
          text: String(opt),
          item: opt,
        };
      }
      if (typeof opt === 'object' && Object.values(opt).length === 1) {
        return {
          value: Object.keys(opt).join(''),
          text: Object.values(opt).join(', '),
          item: opt,
        };
      }
      if (typeof opt === 'object' && 'name' in opt) {
        if ('data' in opt && typeof opt.data === 'object') {
          return {
            value: opt.name,
            text: String(opt?.data?.title || opt.name || ''),
            item: opt,
          };
        }
        if (
          'data' in opt &&
          (typeof opt.data === 'string' || typeof opt.data === 'number')
        ) {
          return {
            value: opt.name,
            text: String(opt?.data),
            item: opt,
          };
        }
        return {
          value: opt.name,
          text: String(opt.name),
          item: opt,
        };
      }
      return { value: '', text: '', item: '' };
    });
  }
  if (typeof options === 'object') {
    return Object.entries(options).map(([value, text]) => ({
      value,
      text: String(text),
      item: value,
    }));
  }
  return [];
};
