import React from 'react';

function LockIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="lock">
        <path
          id="Vector"
          d="M8.25 5.25V3.375C8.25 2.13236 7.24264 1.125 6 1.125C4.75736 1.125 3.75 2.13236 3.75 3.375V5.25M3.375 10.875H8.625C9.24632 10.875 9.75 10.3713 9.75 9.75V6.375C9.75 5.75368 9.24632 5.25 8.625 5.25H3.375C2.75368 5.25 2.25 5.75368 2.25 6.375V9.75C2.25 10.3713 2.75368 10.875 3.375 10.875Z"
          stroke="#CC3342"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default LockIcon;
