import { ReactNode, useRef } from 'react';
import Warning from '../components/Warning';
import TextareaAutosize from 'react-textarea-autosize';
import LockChip from '../components/LockChip';
import { FieldWrapper, LabelSlot } from '../components';
import styles from './TextArea.module.css';
import clx from 'classnames';

interface MTextAreaProps {
  className?: string;
  dataTestId?: string;
  inputRef?: React.Ref<HTMLTextAreaElement>;
  isLocked?: boolean;
  label?: string;
  labelAdditional?: string | ReactNode;
  maxLength?: number;
  name?: string;
  /** @deprecated open */
  open?: boolean;
  placeHolder?: string;
  rows?: number;
  value?: string;
  warning?: boolean;
  warningMessage?: string;
  lockedMessage?: string;
  withMargin?: boolean;
  handleBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  handleChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  handleUnLockField?: (name: string) => void;
  handleClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  isRemoveFontFamily?: boolean;
}

export const MTextArea = ({
  className,
  dataTestId = 'mui-textarea',
  inputRef,
  isLocked,
  label,
  labelAdditional,
  maxLength,
  name,
  placeHolder,
  rows = 4,
  value = '',
  warning,
  warningMessage,
  withMargin = true,
  handleBlur,
  handleChange,
  handleUnLockField,
  lockedMessage,
  handleClick,
  isRemoveFontFamily = false,
}: MTextAreaProps) => {
  const rowsRef = useRef<number>(rows);

  const onFoccus = () => {};
  const onBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    handleBlur && handleBlur(event);
  };

  const handleChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (typeof maxLength === 'number' && e.target.value.length > maxLength) {
      return;
    }

    const textareaLineHeight = 18;
    const previousRows = e.target.rows;
    const currentRows = ~~(e.target.scrollHeight / textareaLineHeight);
    if (currentRows === previousRows) {
      e.target.rows = currentRows;
    }
    (rowsRef.current = currentRows), handleChange && handleChange(e);
  };

  return (
    <>
      <FieldWrapper
        className={clx(styles.FieldWrapper, className, {
          [styles.Locked]: isLocked,
          [styles.Error]: warning,
          [styles.Margin]: withMargin,
        })}
        data-testid={dataTestId}
      >
        <LabelSlot
          label={label}
          right={
            labelAdditional
              ? labelAdditional
              : typeof maxLength === 'number'
              ? `${value.length}/${maxLength}`
              : null
          }
        />
        <div className={styles.InputWrapper} onClick={handleClick}>
          <TextareaAutosize
            placeholder={placeHolder}
            rows={rowsRef.current}
            aria-invalid="false"
            id="outlined-multiline-static"
            className={clx(
              isRemoveFontFamily ? styles.InputWithOutFont : styles.Input,
              {
                [styles.InputWarning]: warning,
                [styles.InputLocked]: isLocked,
              }
            )}
            onFocus={onFoccus}
            onBlur={onBlur}
            value={value}
            onChange={handleChangeTextArea}
            name={name}
            autoComplete="off"
            disabled={isLocked}
            ref={inputRef}
            style={{
              resize: 'none',
            }}
          />
          {isLocked && (
            <div className={styles.Overlay}>
              <LockChip
                lockedMessage={lockedMessage}
                name={name}
                handleUnLockField={handleUnLockField}
              />
            </div>
          )}
        </div>

        {warningMessage && warning && <Warning txt={warningMessage} />}
      </FieldWrapper>
    </>
  );
};
