import { ThemeContext } from 'common/context/Theme';
import { themeTypes } from 'common/context/Theme/ThemeContext';
import { useContext } from 'react';

const InputWrapper = ({ children }: any) => {
  const { secondaryColor } = useContext<themeTypes>(ThemeContext);
  return (
    <>
      <style jsx>{`
        .tab_wrapper {
          height: 45px;
        }
      `}</style>
      <div className="tab_wrapper">
        <div
          style={{
            padding: '10px 14px',
            fontSize: '14px',
            fontFamily: 'Proxima Soft',
            color: secondaryColor,
            zIndex: 2000,
            display: 'flex',
          }}
        >
          {children}
        </div>

        <span className="notranslate" />
      </div>
    </>
  );
};

export default InputWrapper;
