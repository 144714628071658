import React from 'react';
import { NumberBlock } from './NumberBlock';
import styles from '../TimePicker.module.css';

interface NumberSelectorProps {
  value: number;
  min: number;
  max: number;
  handleSelect: (number: number) => void;
}

export const NumberSelector = ({
  value,
  min,
  max,
  handleSelect,
}: NumberSelectorProps) => {
  const renderNumberBlocks = () => {
    const numberBlocks = [];
    for (let i = min; i <= max; i++) {
      numberBlocks.push(
        <NumberBlock
          key={i}
          value={i}
          selected={value === i}
          onClick={handleSelect}
        />,
      );
    }
    return numberBlocks;
  };

  return <div className={styles.Selector}>{renderNumberBlocks()}</div>;
};
