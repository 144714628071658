import { useState } from 'react';
import styles from './CalendarPicker.module.css';
import { ArrowDown, FieldInput, FieldWrapper, LabelSlot } from '../components';
import { displayInputDate } from './utils';
import Calendar from 'react-calendar';
import { RxCalendar } from 'react-icons/rx';
import clx from 'classnames';

interface CalendarPickerProps {
  className?: string;
  popupClassName?: string;
  label: string;
  date?: Date | string | null;
  placeholder?: string;
  onSelectDate: (date: Date) => void;
  dateTestId?: string;
  minDate?: Date;
}

export const CalendarPicker = ({
  className,
  popupClassName,
  label,
  date,
  placeholder,
  onSelectDate,
  dateTestId,
  minDate,
}: CalendarPickerProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const toggle = () => setOpen((v) => !v);

  return (
    <div className={clx(styles.Wrapper, className)}>
      <FieldWrapper withMargin={false}>
        {label && <LabelSlot label={label} />}
        <div
          style={{
            cursor: 'pointer',
          }}
        >
          <FieldInput
            className={styles.Input}
            onClick={toggle}
            Icon={RxCalendar}
            value={date ? displayInputDate(new Date(date)) : placeholder}
            right={<ArrowDown open={open} />}
            isActive={open}
          />

          {open && (
            <div className={clx(styles.Selectors, popupClassName)}>
              <Calendar
                minDate={minDate}
                defaultValue={new Date()}
                onChange={(date: Date) => {
                  onSelectDate(date);
                  setOpen(false);
                }}
                value={date ? new Date(date) : null}
                calendarType="Hebrew"
              />
            </div>
          )}
        </div>
      </FieldWrapper>
    </div>
  );
};
